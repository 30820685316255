<cft-dialog *cftI18n="let t" size="sm" [title]="t('shared.support.dialog.title')">
  <cft-dialog-content>
    <div class="flex flex-col" *ngIf="props.contact as contact">
      <div class="flex flex-row space-x-sm">
        <cft-support-portrait [imageUrl]="contact.portraitUrl" [name]="contact.name" size="sm"></cft-support-portrait>
        <div>
          <p class="text-base !font-semibold">{{ contact.name }}</p>
          <p class="text-sm">{{ contact.role }}</p>
        </div>
      </div>
      <cft-horizontal-list-card
        [icon]="phoneIconDef"
        [title]="contact.phone"
        [description]="t('support.operatingHours')!"
        [hoverEffect]="false"
        iconSize="sm"
        class="mt-md"
      >
        <div class="space-x-lg text-president-200">
          <button [cdkCopyToClipboard]="contact.phone" (cdkCopyToClipboardCopied)="itemCopied($event, phoneCopied$)">
            <fa-icon [icon]="copyIcon" *ngIf="(phoneCopied$ | async) === false"></fa-icon>
            <fa-icon [icon]="copiedIcon" *ngIf="phoneCopied$ | async"></fa-icon>
          </button>
          <a [href]="contact.phone | bgoTel">
            <fa-icon [icon]="phoneIcon"></fa-icon>
          </a>
        </div>
      </cft-horizontal-list-card>
      <cft-horizontal-list-card
        [icon]="emailIconDef"
        [title]="contact.email"
        [hoverEffect]="false"
        iconSize="sm"
        class="mt-sm"
      >
        <div class="space-x-lg text-president-200">
          <button [cdkCopyToClipboard]="contact.email" (cdkCopyToClipboardCopied)="itemCopied($event, emailCopied$)">
            <fa-icon [icon]="copyIcon" *ngIf="(emailCopied$ | async) === false"></fa-icon>
            <fa-icon [icon]="copiedIcon" *ngIf="emailCopied$ | async"></fa-icon>
          </button>
          <a [href]="contact.email | bgoMailTo">
            <fa-icon [icon]="emailIcon"></fa-icon>
          </a>
        </div>
      </cft-horizontal-list-card>
    </div>
  </cft-dialog-content>
</cft-dialog>
